// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/danceableapp/client/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkin-js": () => import("/opt/danceableapp/client/src/pages/checkin.js" /* webpackChunkName: "component---src-pages-checkin-js" */),
  "component---src-pages-classes-js": () => import("/opt/danceableapp/client/src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-index-js": () => import("/opt/danceableapp/client/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/danceableapp/client/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-people-js": () => import("/opt/danceableapp/client/src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/danceableapp/client/.cache/data.json")

